<template>
  <div id="app">
    <div class="wrapper">
      <appHeader />
      <div class="main-view">
        <router-view :key="$route.path" />
      </div>
      <appFooter />
    </div>
    <authModal />
    <donateCardModal />
    <iconsCollection />
    <writeAMessageModal />
    <reportModal />
    <subscriptionModal />
    <telegramAddModal />
    <verifyPhoneModal />

    <a target="_blank" class="support" href="https://t.me/prykhystok_bot">
      <b-icon-chat-dots-fill />
      <span> Підтримка </span>
    </a>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import authModal from "@/components/modals/auth-modal.vue";
import donateCardModal from "@/components/modals/donate-card-modal.vue";
import writeAMessageModal from "@/components/modals/write-a-message.vue";
import reportModal from "@/components/modals/report-house-modal.vue";
import iconsCollection from "@/components/icons/icons-collection.vue";
import appFooter from "@/components/footer/footer.vue";
import subscriptionModal from "@/components/modals/create-subscription-modal.vue";
import telegramAddModal from "@/components/modals/telegram-add-modal.vue";
import verifyPhoneModal from "@/components/modals/phone-verify-modal.vue";
import { Accessibility } from "accessibility";
export default {
  components: {
    appHeader,
    appFooter,
    authModal,
    donateCardModal,
    iconsCollection,
    writeAMessageModal,
    reportModal,
    subscriptionModal,
    telegramAddModal,
    verifyPhoneModal,
  },
  data() {
    return {};
  },
  computed: {
    userData() {
      return this.$store.state.user;
    },
    isUser() {
      return this.$store.getters["isUser"];
    },
  },
  methods: {
    signOut() {
      this.$auth.logOut();
    },
  },
  mounted() {
    this.$store.dispatch("fetchDatasets");
    document.title = "Прихисток - прихисти своїх";
    if (this.isUser) {
      setInterval(() => {
        this.$store.dispatch("getUnreadsCount");
      }, 5000);
    }
    let options = {
      icon: {
        img: ["accessible"],
        position: {
          bottom: { size: 20, units: "px" },
          left: { size: 0, units: "px" },
          type: "fixed",
        },
      },
    };
    let labels = {
      resetTitle: "Скинути",
      closeTitle: "Закрити",
      menuTitle: "Меню доступності",
      increaseText: "Збільшити розмір тексту",
      decreaseText: "Зменшити розмір тексту",
      increaseTextSpacing: "Збільшити інтервал між текстом",
      decreaseTextSpacing: "Зменшити інтервал між текстом",
      increaseLineHeight: "Збільшити висоту рядка",
      decreaseLineHeight: "Зменшити висоту рядка",
      invertColors: "Інвертувати кольори",
      grayHues: "Відтінки сірого",
      underlineLinks: "Підкреслити посилання",
      bigCursor: "Великий курсор",
      readingGuide: "Навігатор читання",
      textToSpeech: "Текст в голос",
      speechToText: "Голос в текст",
      disableAnimations: "Вимкнути анімації",
      hotkeyPrefix: "Гаряча клавіша:",
    };
    options.labels = labels;
    window.addEventListener(
      "load",
      function () {
        new Accessibility(options);
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>
#app {
  .wrapper {
    display: grid;
    background: #f9f9f9;
    // create a grid with 3 rows for header, main content and footer
    //create areas
    grid-template-areas:
      "main"
      "footer";
    //set the height of the grid
    grid-template-rows: 1fr auto;
    min-height: 100vh;
    //strick footer to bottom
    position: relative;
  }
}
.main-view {
  padding-top: 152px;
}
.support {
  position: fixed;
  right: 40px;
  bottom: 30px;
  background: rgb(255, 51, 0);
  z-index: 1000;
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0px 8px 12px 0px rgba(#000, 0.2);
  text-decoration: none;
  span {
    margin-left: 12px;
    display: inline-block;
  }
}
</style>
